import { frontendURL } from '../../../../helper/URLHelper';

const supervisorAgents = accountId => ({
  parentNav: 'supervisorAgents',
  routes: ['all_agents', 'blocked_agents', 'supervisor_agents'],
  menuItems: [
    {
      icon: 'people',
      label: 'BLOCKED_AGENTS',
      hasSubMenu: false,
      toState: frontendURL(
        `accounts/${accountId}/supervisor_agents/blocked_agents`
      ),
      toStateName: 'blocked_agents',
    },
    {
      icon: 'people',
      label: 'ALL_AGENTS',
      hasSubMenu: false,
      toState: frontendURL(
        `accounts/${accountId}/supervisor_agents/all_agents`
      ),
      toStateName: 'all_agents',
    },
  ],
});

export default supervisorAgents;

import Index from './Index';
import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/apps-configuration'),
      component: SettingsContent,
      props: {
        headerTitle: 'APPS_CONFIGURATION.HEADER',
        icon: 'filter',
        showNewButton: false,
      },
      children: [
        {
          path: 'funnels',
          name: 'funnel_list',
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};

/* global axios */

import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  getAllAgents() {
    return axios.get(`${this.url}/all_my_agents`);
  }

  addExistsAgents(data) {
    return axios.post(`${this.url}/add_exists_agents`, data);
  }

  bulkInvite({ users }) {
    return axios.post(`${this.url}/bulk_create`, {
      users,
    });
  }
}

export default new Agents();

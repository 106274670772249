/* global axios */
import ApiClient from './ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  createTelephony(data) {
    const formData = new FormData();
    formData.append('campaign[title]', data.title);
    formData.append('campaign[message]', data.message);
    formData.append('campaign[inbox_id]', data.inbox_id);
    formData.append('campaign[scheduled_at]', data.scheduled_at);
    formData.append('campaign[audience]', JSON.stringify(data.audience));
    formData.append('campaign[days_duplicity]', data.days_duplicity);
    formData.append('campaign[secondary_inbox_id]', data.secondary_inbox_id);
    formData.append(
      'campaign[template_params]',
      JSON.stringify(data.template_params)
    );
    formData.append('campaign[type]', 'telephony');
    formData.append('campaign[import_file]', data.import_file);

    return axios.post(this.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default new CampaignsAPI();

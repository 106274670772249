<template>
  <div class="column content-box">
    <div class="row-content p-2">
      <input
        v-model="dateQuery"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data"
      />
      <div class="div-button">
        <woot-button
          color-scheme="success"
          icon="search"
          :disabled="!disabledButton"
          @click="fetchLoginData"
        >
          Buscar
        </woot-button>
      </div>
    </div>

    <div v-if="loading" class="small-12 medium-5 conversation-metric p-2">
      <span>{{ $t('CAMPAIGN_REPORTS.TABLE.LOADING_MESSAGE') }}</span>
    </div>

    <div v-if="infos.length" class="row">
      <div data-v-43accf8a="" class="card">
        <div data-v-43accf8a="" class="card-body row">
          <div
            data-v-2cdc6c1a=""
            class="agent-table-container"
            data-v-43accf8a=""
          >
            <ve-table
              :fixed-header="true"
              :columns="columns"
              :table-data="infos"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable } from 'vue-easytable';
import usersLogin from '../../../../api/getUsersLogins';

export default {
  components: {
    VeTable,
  },
  data() {
    return {
      infos: [],
      dateQuery: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      labels: 'labels/getLabels',
      accountId: 'getCurrentAccountId',
    }),
    columns() {
      return [
        {
          field: 'agent_name',
          key: 'agent_name',
          title: 'Operador',
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'online',
          key: 'online',
          title: 'Online',
          align: 'left',
          width: 10,
        },
        {
          field: 'lunch',
          key: 'lunch',
          title: 'Almoço',
          align: 'left',
          width: 10,
        },
        {
          field: 'busy',
          key: 'busy',
          title: 'Ocupado',
          align: 'left',
          width: 10,
        },
        {
          field: 'offline',
          key: 'offline',
          title: 'Offline',
          align: 'left',
          width: 10,
        },
      ];
    },
    disabledButton() {
      return Boolean(this.dateQuery.length === 10);
    },
  },
  methods: {
    async fetchLoginData() {
      this.loading = true;
      const date = `${this.dateQuery.split('/')[2]}-${
        this.dateQuery.split('/')[1]
      }-${this.dateQuery.split('/')[0]}`;
      const { data } = await usersLogin.getUsersLogin(this.accountId, date);
      this.infos = data.users_logins;

      this.loading = false;
    },
  },
};
</script>
<style>
.row-content {
  display: flex;
}

.div-button {
  width: 130px;
  margin-left: 10px;
}
</style>

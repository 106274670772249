import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import FlowAPI from '../../api/flow';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getFlows: $state => {
    return $state.records;
  },
  getStageDeals: $state => {
    return $state;
  },
  getFlow: $state => id => {
    return $state.records.find(t => t.id === id);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_FLOWS_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await FlowAPI.get();
      commit(types.SET_FLOWS, response.data);
      commit(types.SET_FLOWS_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.SET_FLOWS_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  create: async ({ commit }, obj) => {
    commit(types.SET_FLOWS_UI_FLAG, { isCreating: true });
    try {
      const { data } = await FlowAPI.create(obj);
      commit(types.CREATED_FLOW, data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_FLOWS_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_FLOWS_UI_FLAG, { isUpdating: true });
    try {
      const response = await FlowAPI.update(id, updateObj);
      commit(types.EDIT_FLOW, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_FLOWS_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_FLOWS_UI_FLAG, { isDeleting: true });
    try {
      await FlowAPI.delete(id);
      commit(types.DELETE_FLOW, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_FLOWS_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_FLOWS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_FLOWS]: MutationHelpers.set,
  [types.DELETE_FLOW]: MutationHelpers.destroy,
  [types.CREATED_FLOW]: MutationHelpers.create,
  [types.EDIT_FLOW]: MutationHelpers.update,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

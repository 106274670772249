<template>
  <div class="flex-1 overflow-auto p-2">
    <div class="ml-1">
      <report-filter-selector
        :show-agents-filter="true"
        :show-inbox-filter="true"
        :show-rating-filter="false"
        :show-team-filter="false"
        :show-business-hours-switch="false"
        :only-telephony-inboxes="true"
        @filter-change="onFilterChange"
      />
    </div>

    <div class="flex-1 w-full max-w-full conversation-metric">
      <metric-card
        :header="$t('OVERVIEW_REPORTS.ACCOUNT_CALLS.HEADER')"
        :is-loading="accountCallFetching"
        :loading-message="$t('OVERVIEW_REPORTS.ACCOUNT_CALLS.LOADING_MESSAGE')"
      >
        <div class="metric-content flex-1 min-w-0">
          <h3 class="heading">
            {{ $t('OVERVIEW_REPORTS.ACCOUNT_CALLS.QUANTITY') }}
          </h3>
          <p class="metric">{{ accountCall.calls_count }}</p>
        </div>
        <div class="metric-content flex-1 min-w-0">
          <h3 class="heading">
            {{ $t('OVERVIEW_REPORTS.ACCOUNT_CALLS.AVERAGE') }}
          </h3>
          <p class="metric">
            {{
              $t('OVERVIEW_REPORTS.ACCOUNT_CALLS.TIME', {
                seconds: accountCall.avg_call_time,
              })
            }}
          </p>
        </div>
      </metric-card>
    </div>

    <metric-card :live="false" :header="$t('CALL_REPORTS.GROUP_CALLS')">
      <div class="call--table-container">
        <ve-table
          v-if="!accountCallFetching"
          max-height="calc(80vh - 21.875rem)"
          :fixed-header="true"
          :border-around="true"
          :columns="columnsAgents"
          :table-data="accountCall.call_agents_group"
        />
        <div v-if="accountCallFetching" class="call--empty-records">
          <span>{{ $t('CALL_REPORTS.LOADING_MESSAGE') }}</span>
        </div>
        <div
          v-if="!accountCallFetching && hasDocuments"
          class="call--empty-records"
        >
          {{ $t('CALL_REPORTS.EMPTY') }}
        </div>
      </div>
    </metric-card>

    <metric-card :live="false" :header="$t('CALL_REPORTS.ALL_CALLS')">
      <div class="call--table-container">
        <ve-table
          v-if="!accountCallFetching"
          max-height="calc(100vh - 21.875rem)"
          :fixed-header="true"
          :border-around="true"
          :columns="columns"
          :table-data="accountCall.calls"
        />
        <div v-if="accountCallFetching" class="call--empty-records">
          <span>{{ $t('CALL_REPORTS.LOADING_MESSAGE') }}</span>
        </div>
        <div
          v-if="!accountCallFetching && hasDocuments"
          class="call--empty-records"
        >
          {{ $t('CALL_REPORTS.EMPTY') }}
        </div>
      </div>
    </metric-card>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import 'vue-easytable/libs/theme-default/index.css';
import MetricCard from './components/overview/MetricCard';
import ReportFilterSelector from './components/FilterSelector.vue';

import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ReportFilterSelector,
    VeTable,
    MetricCard,
  },
  mixins: [alertMixin],
  data() {
    return {
      searchValue: '',
      inboxId: {},
      loading: false,
      dataFinding: false,
      pageIndex: 1,
      from: 0,
      to: 0,
      userIds: [],
      inbox: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      accountCall: 'getAccountCall',
      accountCallFetching: 'getAccountCallFetching',
    }),
    hasDocuments() {
      return !this.accountCall.calls.length;
    },
    inboxes() {
      return this.$store.getters['inboxes/getInboxesTelephony'];
    },
    columns() {
      return [
        {
          field: 'assignee',
          key: 'assignee',
          title: this.$t('CALL_REPORTS.TABLE.HEADER.AGENT'),
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'call_duration',
          key: 'call_duration',
          title: this.$t('CALL_REPORTS.TABLE.HEADER.SECONDS'),
          align: 'left',
          width: 10,
        },
        {
          field: 'contact',
          key: 'contact',
          title: this.$t('CALL_REPORTS.TABLE.HEADER.CLIENT_PHONE_NUMBER'),
          align: 'left',
          width: 10,
        },
        {
          field: 'price',
          key: 'price',
          title: this.$t('CALL_REPORTS.TABLE.HEADER.VALUE'),
          align: 'left',
          width: 10,
        },
        {
          field: 'status',
          key: 'status',
          title: this.$t('CALL_REPORTS.TABLE.HEADER.STATUS'),
          align: 'left',
          width: 10,
        },
      ];
    },
    columnsAgents() {
      return [
        {
          field: 'assignee',
          key: 'assignee',
          title: this.$t('CALL_REPORTS.TABLE.HEADER.AGENT'),
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'success',
          key: 'success',
          title: this.$t('CALL_REPORTS.STATUS.SUCCESS'),
          align: 'left',
          width: 10,
        },
        {
          field: 'failed',
          key: 'failed',
          title: this.$t('CALL_REPORTS.STATUS.FAILED'),
          align: 'left',
          width: 10,
        },
        {
          field: 'rejected',
          key: 'rejected',
          title: this.$t('CALL_REPORTS.STATUS.REJECTED'),
          align: 'left',
          width: 10,
        },
        {
          field: 'canceled',
          key: 'canceled',
          title: this.$t('CALL_REPORTS.STATUS.CANCELED'),
          align: 'left',
          width: 10,
        },
        {
          field: 'total_call_duration',
          key: 'total_call_duration',
          title: this.$t('CALL_REPORTS.TABLE.HEADER.SECONDS'),
          align: 'left',
          width: 10,
        },
      ];
    },
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    async getAllData() {
      try {
        await this.$store.dispatch('fetchAccountCall', {
          to: this.to,
          from: this.from,
          inbox_id: this.inbox,
          user_ids: this.userIds,
        });
      } catch {
        this.showAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
      }
    },
    onFilterChange({ from, to, selectedAgents, selectedInbox }) {
      this.from = from;
      this.to = to;
      this.userIds = selectedAgents.map(el => el.id);
      this.inbox = selectedInbox?.id;

      this.getAllData();
    },
  },
};
</script>

<style lang="scss" scoped>
.call--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    @apply bg-white dark:bg-slate-900;

    &::v-deep {
      .ve-table-container {
        border-radius: var(--border-radius-normal);
      }

      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-normal) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-small) var(--space-normal) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .emoji-response {
    font-size: var(--font-size-large);
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.call--empty-records {
  align-items: center;
  border-top: 0;
  display: flex;
  font-size: var(--font-size-small);
  height: 12.5rem;
  justify-content: center;
  margin-top: -1px;
  width: 100%;
  @apply text-slate-600 dark:text-slate-200 bg-white dark:bg-slate-900 border border-t-0 border-solid border-slate-75 dark:border-slate-700;
}

.row-content {
  display: flex;
}

.div-button {
  width: 130px;
  margin-left: 10px;
}
</style>

<template>
  <div class="column content-box p-2">
    <div class="row-content">
      <input
        v-model="initialDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de inicio"
        style="height: 46px"
      />
      <input
        v-model="endDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de fim"
        style="margin-left: 10px; height: 46px"
      />
      <multiselect
        v-model="inboxId"
        placeholder="Selecione uma caixa de entrada"
        label="name"
        track-by="id"
        :options="inboxes"
        :show-labels="false"
        style="margin-left: 10px"
      />
      <div class="div-button">
        <woot-button
          :disabled="!disabledButton"
          color-scheme="success"
          style="height: 46px"
          @click="fetchTabulationData"
        >
          Buscar
        </woot-button>
      </div>
    </div>
    <div v-if="loading" class="flex w-full justify-center">
      <spinner v-if="loading" />
    </div>

    <div v-if="dataFinding">
      <span>{{ $t('CAMPAIGN_REPORTS.TABLE.NO_CONTENT') }}</span>
    </div>

    <metric-card
      v-if="reportsData.length"
      :live="false"
      :header="'Dados das Conversas Abertas'"
    >
      <ve-table
        :fixed-header="true"
        :columns="columns"
        :table-data="reportsData"
        :max-height="650"
      />
    </metric-card>

    <metric-card
      v-if="tabulationData.length"
      :live="false"
      :header="'Dados das Tabulações Realizadas'"
    >
      <ve-table
        :fixed-header="true"
        :columns="columnsTabulation"
        :table-data="tabulationData"
        :max-height="650"
      />
    </metric-card>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import reportsTabulation from '../../../../api/getTabulationData';
import MetricCard from './components/overview/MetricCard';
import Spinner from 'shared/components/Spinner.vue';

import { mapGetters } from 'vuex';
import { format } from 'date-fns';
export default {
  components: {
    VeTable,
    MetricCard,
    Spinner,
  },
  data() {
    return {
      inboxId: {},
      reportsData: [],
      tabulationData: [],
      initialDate: format(new Date(), 'dd/MM/yyyy'),
      endDate: format(new Date(), 'dd/MM/yyyy'),
      loading: false,
      dataFinding: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
    }),
    columns() {
      return [
        {
          field: 'name',
          key: 'name_1',
          title: 'Operador',
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'leads_trab',
          key: 'leads_trab_1',
          title: 'Leads Trab',
          align: 'left',
          width: 10,
        },
        {
          field: 'leads_received',
          key: 'leads_received_1',
          title: 'Leads Recebidos',
          align: 'left',
          width: 10,
        },
        {
          field: 'opened',
          key: 'opened_1',
          title: 'Abertas',
          align: 'left',
          width: 10,
        },
        {
          field: 'resolveds',
          key: 'resolveds_1',
          title: 'Resolvidas',
          align: 'left',
          width: 10,
        },
        {
          field: 'success',
          key: 'success_1',
          title: 'Sucesso',
          align: 'left',
          width: 10,
        },
        {
          field: 'refused',
          key: 'refused_1',
          title: 'Recusas',
          align: 'left',
          width: 10,
        },
        {
          field: 'out_target',
          key: 'out_target_1',
          title: 'Fora Target',
          align: 'left',
          width: 10,
        },
        {
          field: 'not_tabulated',
          key: 'not_tabulated_1',
          title: 'Não Tabuladas',
          align: 'left',
          width: 10,
        },
      ];
    },
    columnsTabulation() {
      return [
        {
          field: 'name',
          key: 'name',
          title: 'Operador',
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'resolveds',
          key: 'resolved',
          title: 'Resolvidas',
          align: 'left',
          width: 10,
        },
        {
          field: 'success',
          key: 'success',
          title: 'Sucesso',
          align: 'left',
          width: 10,
        },
        {
          field: 'refused',
          key: 'refused',
          title: 'Recusas',
          align: 'left',
          width: 10,
        },
        {
          field: 'out_target',
          key: 'out_target',
          title: 'Fora target',
          align: 'left',
          width: 10,
        },
        {
          field: 'not_tabulated',
          key: 'not_tabulated',
          title: 'Não Tabuladas',
          align: 'left',
          width: 10,
        },
      ];
    },
    disabledButton() {
      return Boolean(
        this.initialDate.length === 10 &&
          this.endDate.length === 10 &&
          Object.keys(this.inboxId).length
      );
    },
  },
  methods: {
    async fetchTabulationData() {
      this.loading = true;
      this.dataFinding = false;
      this.reportsData = [];
      this.tabulationData = [];
      const init = String(this.initialDate).split('/');
      const end = String(this.endDate).split('/');
      const initialDate = `${init[2]}-${init[1]}-${init[0]}`;
      const endDate = `${end[2]}-${end[1]}-${end[0]}`;
      const inbox = this.inboxId.id;

      try {
        const { data } = await reportsTabulation.getReportsTabulation(
          this.accountId,
          initialDate,
          endDate,
          inbox
        );

        this.reportsData = data.two;
        this.tabulationData = data.three;

        if (!data.two.length && !data.three.length) {
          this.dataFinding = true;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.row-content {
  display: flex;
}

.div-button {
  width: 130px;
  margin-left: 10px;
}
</style>

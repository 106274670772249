/* global axios */
import ApiClient from './ApiClient';

class Call extends ApiClient {
  constructor() {
    super('call', { accountScoped: true });
  }

  getCalls(contactId) {
    return axios.get(`${this.url}/${contactId}/contact_calls`);
  }

  createCountCalls() {
    return axios.get(`${this.url}/create_count_call`);
  }
}

export default new Call();

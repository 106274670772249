<template>
  <div class="agent-table-container">
    <ve-table
      max-height="calc(100vh - 35rem)"
      :fixed-header="true"
      :columns="columnsTable"
      :table-data="tableData"
    />
    <div v-if="isLoading">
      <span>{{ $t('CAMPAIGN_REPORTS.TABLE.LOADING_MESSAGE') }}</span>
    </div>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import Thumbnail from '../../../../../../components/widgets/Thumbnail.vue';

export default {
  name: 'CampaignTable',
  components: {
    VeTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => ['contact', 'phoneNumber', 'status'],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    tableData() {
      return this.data.map(message => ({
        contactId: message.contactId,
        thumbnail: message.contact?.thumbnail,
        contact: message.contact?.name,
        contactStatus: message.contact?.availability_status,
        phoneNumber: message.phoneNumber,
        status: message.status,
        email: message.email,
      }));
    },
    columnsTable() {
      const columsData = {
        contact: {
          field: 'contact',
          key: 'contact',
          title: this.$t('CAMPAIGN_REPORTS.TABLE.HEADER.CONTACT'),
          fixed: 'left',
          align: 'left',
          width: 25,
          renderBodyCell: ({ row }) => (
            <div class="row-user-block">
              <Thumbnail
                src={row.thumbnail}
                size="32px"
                username={row.contact}
                status={row.contactStatus}
              />
              <div class="user-block">
                <router-link
                  to={`/app/accounts/${this.$route.params.accountId}/contacts/${row.contactId}`}
                  class="user-name"
                >
                  <h6 class="title text-truncate">{row.contact}</h6>
                  <span>
                    {this.$t('CAMPAIGN_REPORTS.TABLE.VIEW_CONTACT_DETAILS')}
                  </span>
                </router-link>
              </div>
            </div>
          ),
        },
        phoneNumber: {
          field: 'phoneNumber',
          key: 'phoneNumber',
          title: this.$t('CAMPAIGN_REPORTS.TABLE.HEADER.PHONE_NUMBER'),
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        email: {
          field: 'email',
          key: 'email',
          title: 'Email',
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        status: {
          field: 'status',
          key: 'status',
          title: this.$t('CAMPAIGN_REPORTS.TABLE.HEADER.STATUS'),
          align: 'left',
          width: 10,
        },
      };

      const enabledColums = this.columns.map(c => columsData[c]);

      return enabledColums;
    },
  },
};
</script>

<style lang="scss" scoped>
.row-user-block {
  align-items: center;
  display: flex;
  text-align: left;

  .user-block {
    display: flex;
    flex-direction: column;
    min-width: 0;
    .title {
      font-size: var(--font-size-small);
      margin: var(--zero);
      line-height: 1;
    }
    .sub-title {
      font-size: var(--font-size-mini);
    }
  }

  .user-thumbnail-box {
    margin-right: var(--space-small);
  }
}
</style>

<template>
  <div class="multiselect-wrap--small">
    <multiselect
      v-model="selectedOption"
      class="no-margin"
      :placeholder="$t('INBOX_REPORTS.FILTER_DROPDOWN_LABEL')"
      label="name"
      track-by="id"
      :options="onlyTelephonyInboxes ? telephonyInboxes : options"
      :option-height="24"
      :show-labels="false"
      @input="handleInput"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportsFiltersInboxes',
  props: {
    onlyTelephonyInboxes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  computed: {
    ...mapGetters({
      options: 'inboxes/getInboxes',
      telephonyInboxes: 'inboxes/getInboxesTelephony',
    }),
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
  },
  methods: {
    inboxes() {
      return this.onlyTelephonyInboxes ? this.telephonyInboxes : this.options;
    },
    handleInput() {
      this.$emit('inbox-filter-selection', this.selectedOption);
    },
  },
};
</script>

<template>
  <div>
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.phoneNumber.$error }">
          <span>
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
          </span>
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')
            "
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.url.$error }">
          <span>
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.URL.LABEL') }}
          </span>
          <input
            v-model.trim="url"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.URL.PLACEHOLDER')"
            @blur="$v.url.$touch"
          />
          <span v-if="$v.url.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.URL.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty, shouldBeUrl } from 'shared/helpers/Validators';
import axios from 'axios';

export default {
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      url: '',
    };
  },
  computed: {
    ...mapGetters({ 
      uiFlags: 'inboxes/getUIFlags', 
      inboxes: 'inboxes/getInboxes',
      currentAccountId: 'getCurrentAccountId',
    }),
  },
  validations: {
    phoneNumber: { required, isPhoneE164OrEmpty },
    url: { required, shouldBeUrl },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      await axios.post(`${this.url.trim()}/instance/create`, {
        "instanceName": this.phoneNumber.replace('+', ''),
        "token": "",
        "qrcode": true,
        "chatwoot_account_id": this.currentAccountId,
        "chatwoot_token": "G27J2citV2uQxgevzpxT7Jf8",
        "chatwoot_url": "https://painel.digitalchat.com.br/",
        "chatwoot_sign_msg": true,
        "chatwoot_reopen_conversation": true,
        "chatwoot_conversation_pending": false
      }, {
        headers: {
          'apikey': 'B6D711FCDE4D4FD5936544120E713976',
          'Content-Type': 'application/json'
        }
      });

      await axios.post(`${this.url.trim()}/settings/set/${this.phoneNumber.replace('+', '')}`, {
          "reject_call": false,
          "msg_call": "",
          "groups_ignore": false,
          "always_online": true,
          "read_messages": true,
          "read_status": true
        }, {
        headers: {
          'apikey': 'B6D711FCDE4D4FD5936544120E713976',
          'Content-Type': 'application/json'
        }
      });

      router.replace({
        name: 'settings_inbox_list',
      });
    },
  },
};
</script>

/* global axios */
import ApiClient from './ApiClient';

class UsersLogin extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getUsersLogin(accountId, date) {
    return axios.get(
      `${this.apiVersion}/accounts/${accountId}/users_login?date_query=${date}`
    );
  }
}

export default new UsersLogin();

<template>
  <div class="column content-box">
    <label for="">Selecione um funil abaixo:</label>
    <multiselect
      v-model="funnelId"
      placeholder="Selecione um funil"
      label="name"
      track-by="id"
      :options="funnels"
      :show-labels="false"
      :multiple="false"
      :loading="isLoading"
      @select="getFunnelId"
    />

    <div v-if="showFunnel">
      <h4>Criar funil?</h4>
      <div class="row create-funnel">
        <strong>{{ funnelCreate.name }}</strong>
        <woot-button color-scheme="success" @click="createFunnel()">
          Criar funil
        </woot-button>
      </div>
    </div>

    <div class="column">
      <h4>Funis cadastrados</h4>
      <ul v-for="item in funnelsRegister" :key="item.id">
        <li class="row">
          <strong>{{ item.name }}</strong>
          <woot-button
            variant="smooth"
            color-scheme="alert"
            size="tiny"
            icon="dismiss-circle"
            style="margin-left: 20px"
            @click="deleteFunnel(item.id)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import funnelApi from '../../../../api/funnel';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      funnels: [],
      funnelId: '',
      isLoading: true,
      showFunnel: false,
      funnelCreate: {},
      dealStages: [],
      funnelsExi: [],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      funnelsRegister: 'funnels/getFunnels',
    }),
  },
  mounted() {
    this.$store.dispatch('funnels/get');
    this.getAllFunnels();
  },
  methods: {
    async getAllFunnels() {
      const { data } = await funnelApi.get();

      this.isLoading = false;

      const allIds = this.funnelsRegister.map(item => item.funnel_id);

      this.funnelsExi = this.funnelsRegister;

      this.funnels = data.funnels.filter(item => !allIds.includes(item.id));
    },
    getFunnelId(params) {
      this.funnelCreate = this.funnels.find(f => f.id === params.id);

      this.dealStages = this.funnelCreate.deal_stages;

      this.showFunnel = true;
    },
    async createFunnel() {
      const dataFunnel = {
        funnel_id: this.funnelCreate.id,
        deal_stages: this.funnelCreate.deal_stages.map(item => ({
          id: item.id,
          name: item.name,
        })),
        name: this.funnelCreate.name,
      };

      this.$store.dispatch('funnels/create', dataFunnel);

      this.showFunnel = false;
    },
    async deleteFunnel(id) {
      this.$store.dispatch('funnels/delete', id);
    },
  },
};
</script>

<style>
.create-funnel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<template>
  <div class="column content-box p-2">
    <div class="row-content">
      <input
        v-model="initialDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de inicio"
        style="height: 46px"
      />
      <input
        v-model="endDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de fim"
        style="margin-left: 10px; height: 46px"
      />
      <!-- <input
        v-model="searchText"
        type="text"
        placeholder="Digite um texto"
        style="margin-left: 10px; height: 46px"
      /> -->
      <multiselect
        v-model="label"
        placeholder="Selecione um marcador"
        label="title"
        track-by="id"
        :options="labels"
        :show-labels="false"
        style="margin-left: 10px"
      />
      <div class="div-button">
        <woot-button
          color-scheme="success"
          icon="arrow-download"
          :disabled="!disabledButton"
          style="height: 46px"
          @click="downloadConversationReportsRel"
        >
          Exportar
        </woot-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { format, parseISO, differenceInDays } from 'date-fns';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  mixins: [alertMixin],
  data() {
    return {
      initialDate: '',
      endDate: '',
      // searchText: '',
      isLoading: false,
      label: '',
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      labels: 'labels/getLabels',
    }),
    disabledButton() {
      return Boolean(
        this.initialDate.length === 10 && this.endDate.length === 10
      );
    },
  },
  methods: {
    downloadConversationReportsRel() {
      const initial_date =
        this.initialDate || format(parseISO(new Date()), 'YYYY-MM-DD');
      const end_date =
        this.endDate || format(parseISO(new Date()), 'YYYY-MM-DD');

      const isToLonger = differenceInDays(
        new Date(
          end_date.split('/')[2],
          end_date.split('/')[1],
          end_date.split('/')[0]
        ),
        new Date(
          initial_date.split('/')[2],
          initial_date.split('/')[1],
          initial_date.split('/')[0]
        )
      );

      if (isToLonger > 31) {
        this.showAlert('As datas devem ter no máximo 31 dias de diferença');
      } else if (isToLonger < 0) {
        this.showAlert('Informe datas válidas');
      } else {
        const fileName = `conversation-report-${Date.now()}.xlsx`;
        this.$store.dispatch('downloadConversationReportsRel', {
          from: initial_date,
          to: end_date,
          label: this.label?.title || null,
          // text: this.searchText,
          fileName,
        });
      }
    },
  },
};
</script>

<style>
.row-content {
  display: flex;
}

.div-button {
  width: 130px;
  margin-left: 10px;
}
</style>

<template>
  <section class="flex flex-col bg-white dark:bg-slate-900 p-2 pr-2">
    <div>
      <date-picker
        v-model="snoozeTime"
        type="date"
        inline
        :lang="lang"
        :popup-style="{ width: '100%' }"
        :get-classes="getClasses"
        @calendar-change="getAllConversationMonth"
        @input="chooseDate"
      />
    </div>
    <div class="dark:bg-slate-700 p-2 w-full h-full">
      <div class="flex justify-between">
        <h3 class="text-slate-800 dark:text-slate-75">
          Conversas
          <strong v-if="count" class="text-slate-800 dark:text-slate-75">
            ({{ count }})
          </strong>
        </h3>
        <div class="w-1/2">
          <multiselect
            v-model="selectedOption"
            track-by="id"
            label="name"
            :placeholder="$t('CONVERSATIONS_SCHEDULE.MULTISELECT.SELECT_ONE')"
            selected-label
            deselect-label=""
            :options="options"
            :searchable="false"
            :allow-empty="false"
            @select="changeSelect"
          />
        </div>
      </div>

      <div v-if="isLoading" class="flex w-full justify-center">
        <spinner v-if="isLoading" />
      </div>

      <div class="flex w-full h-4/6 justify-start flex-wrap overflow-y-scroll">
        <ul v-for="con in conversations" :key="con.id" class="w-48">
          <div
            class="bg-white dark:bg-slate-800 border border-solid border-slate-75 dark:border-slate-700/50 rounded-sm mb-4 p-4"
          >
            <div class="flex flex-col justify-center m-0 mx-4 flex-1">
              <h3 class="text-xl text-slate-800 dark:text-slate-100">
                {{ $t(`CONVERSATIONS_SCHEDULE.${con.status.toUpperCase()}`) }}
              </h3>
              <p class="text-ellipsis">
                {{ con.meta.sender.name }}
              </p>
              <p>
                {{ con.meta.sender.phone_number }}
              </p>
            </div>
            <div class="flex justify-center items-center mb-0">
              <router-link
                :to="
                  frontendURL(`accounts/${accountId}/conversations/${con.id}`)
                "
              >
                <woot-button>
                  {{ $t('CONVERSATIONS_SCHEDULE.BUTTON_MESSAGE') }}
                </woot-button>
              </router-link>
            </div>
          </div>
        </ul>

        <woot-button
          v-if="count > 25 && conversations.length !== count"
          variant="clear"
          size="expanded"
          class="load-more--button"
          @click="loadMore"
        >
          {{ $t('CHAT_LIST.LOAD_MORE_CONVERSATIONS') }}
        </woot-button>
      </div>
    </div>
  </section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';

import conversationApi from '../../../api/inbox/conversation';
import Spinner from 'shared/components/Spinner.vue';
import { frontendURL } from '../../../helper/URLHelper';
import { format } from 'date-fns';
import { mapGetters } from 'vuex';

export default {
  components: {
    DatePicker,
    Spinner,
  },
  data() {
    return {
      snoozeTime: new Date(),
      lang: {
        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        yearFormat: 'YYYY',
        monthFormat: 'MMMM',
      },
      isLoading: false,
      conversations: [],
      allConversations: [],
      count: 0,
      actualPage: 1,
      options: [
        {
          id: 'snoozed_until',
          name: 'Adiado para',
          label: 'Adiado para',
        },
        {
          id: 'created_at',
          name: 'Criado em',
          label: 'Criado em',
        },
      ],
      selectedOption: {
        id: 'snoozed_until',
        name: 'Adiado para',
        label: 'Adiado para',
      },
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  mounted() {
    this.chooseTime();
    this.getAllConversationMonth(this.snoozeTime);
  },
  methods: {
    frontendURL,
    getClasses(cellDate) {
      const currentCell = cellDate.toISOString().split('T')[0];
      const allDates = this.allConversations.map(
        item => item.snoozed_until.split('T')[0]
      );

      if (allDates.includes(currentCell)) {
        return 'bg-woot-75 dark:bg-slate-400';
      }

      return '';
    },
    changeSelect() {
      this.chooseTime();
      this.actualPage = 1;
    },
    loadMore() {
      this.actualPage += 1;
      this.chooseTime();
    },
    chooseDate() {
      this.actualPage = 1;
      this.chooseTime();
    },
    async chooseTime() {
      this.isLoading = true;

      if (this.actualPage === 1) {
        this.conversations = [];
      }

      const payload = {
        queryData: {
          payload: [
            {
              attribute_key: this.selectedOption.id,
              attribute_model: 'standard',
              filter_operator: 'exact_date',
              values: [`${format(this.snoozeTime, 'yyyy-MM-dd')}`],
              custom_attribute_type: '',
            },
          ],
        },
        page: this.actualPage,
      };

      const {
        data: {
          payload: con,
          meta: { all_count },
        },
      } = await conversationApi.filter(payload);

      this.isLoading = false;
      this.conversations.push(...con);
      this.count = all_count;
    },
    async getAllConversationMonth(callBack) {
      this.allConversations = [];

      const payload = {
        queryData: {
          payload: [
            {
              attribute_key: 'snoozed_until',
              attribute_model: 'standard',
              filter_operator: 'is_greater_than',
              values: [`${format(callBack, 'yyyy-MM-dd')}`],
              custom_attribute_type: '',
            },
          ],
        },
        page: 1,
      };

      const {
        data: { payload: con },
      } = await conversationApi.filter(payload);

      this.allConversations.push(...con);
    },
  },
};
</script>

import { frontendURL } from '../../../helper/URLHelper';
import Main from './components';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/tutorial'),
      name: 'tutorial',
      roles: ['agent', 'administrator'],
      component: Main,
      children: [],
    },
  ],
};

import Vue from 'vue';

import types from '../mutation-types';
import CallAPI from '../../api/call';

const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isCalling: false,
    inProgess: false,
  },
  dataCreated: {},
  selectInbox: {},
  session: {},
  message: '',
  time: 0,
  timeFormat: '',
};

export const getters = {
  getCalls: $state => {
    return $state.records;
  },
  getCall: $state => id => {
    return $state.records.find(t => t.id === id);
  },
  getCallContact: $state => id => {
    return (
      $state.records[Number(id)]?.filter(item => item.status === 'success') ||
      []
    );
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getMessage($state) {
    return $state.message;
  },
  getFormatTime($state) {
    return $state.timeFormat;
  },
  getTime($state) {
    return $state.time;
  },
  getSession($state) {
    return $state.session;
  },
  getData($state) {
    return $state.dataCreated;
  },
  getInboxSelected($state) {
    return $state.selectInbox;
  },
};

export const actions = {
  get: async ({ commit }, contactId) => {
    commit(types.SET_CALLS_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await CallAPI.getCalls(contactId);
      commit(types.SET_CALLS, {
        id: contactId,
        data: response.data,
      });
      commit(types.SET_CALLS_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.SET_CALLS_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  create: async ({ commit }, obj) => {
    commit(types.SET_CALLS_UI_FLAG, { isCreating: true });
    try {
      const { data } = await CallAPI.create(obj);
      commit(types.CREATED_CALL, {
        id: obj.contact_id,
        data,
      });
      commit(types.SET_CALLS_UI_FLAG_END);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_CALLS_UI_FLAG, { isCreating: false });
    }
  },
  initCall: async ({ commit }, data) => {
    commit(types.SET_CALLS_UI_FLAG_INIT, data);
  },
  endCall: async ({ commit }) => {
    commit(types.SET_CALLS_UI_FLAG_END);
  },
  updateText: async ({ commit }, data) => {
    commit(types.SET_CALLS_TEXT, data);
  },
  updateTime: async ({ commit }, data) => {
    commit(types.SET_CALLS_TIME, data);
  },
  setSession: async ({ commit }, data) => {
    commit(types.SET_CALLS_SESSION, data);
  },
  setInbox: async ({ commit }, data) => {
    commit(types.SET_INBOX_SESSION, data);
  },
};

export const mutations = {
  [types.SET_CALLS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_INBOX_SESSION]($state, data) {
    $state.selectInbox = data;
  },
  [types.SET_CALLS]: ($state, { id, data }) => {
    Vue.set($state.records, id, data);
  },
  [types.SET_CALLS_UI_FLAG_INIT]: ($state, data) => {
    $state.uiFlags = {
      ...$state.uiFlags,
      isCalling: true,
    };
    $state.dataCreated = {
      ...data,
    };
  },
  [types.SET_CALLS_SESSION]: ($state, data) => {
    $state.session = {
      ...data,
    };
  },
  [types.SET_CALLS_UI_FLAG_END]: $state => {
    $state.uiFlags = {
      isFetching: false,
      isCalling: false,
      inProgess: false,
    };
    $state.dataCreated = {};
    $state.session = {};
    $state.message = '';
    $state.time = 0;
    $state.timeFormat = '';
    $state.selectInbox = {};
  },
  [types.SET_CALLS_TEXT]: ($state, { message }) => {
    $state.message = message;
  },
  [types.SET_CALLS_TIME]: ($state, { time, timeFormat }) => {
    $state.time = time;
    $state.timeFormat = timeFormat;
  },
  [types.CREATED_CALL]: ($state, { id, data }) => {
    const calls = $state.records[id] || [];

    const updatedCalls = [...calls];
    const index = calls.findIndex(call => call.id === data.id);

    if (index !== -1) {
      updatedCalls[index] = { ...calls[index], ...data };
    } else {
      updatedCalls.push(data);
    }

    Vue.set($state.records, id, updatedCalls);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

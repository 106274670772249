<template>
  <div class="column content-box mb-10">
    <div class="small-12 medium-3 pull-right m-2">
      <p>
        {{ $t('CAMPAIGN_REPORTS.FILTER_DROPDOWN_LABEL') }}
      </p>
      <multiselect
        v-model="selectedCampaign"
        placeholder="Select"
        label="title"
        track-by="id"
        :options="filterItemsList"
        :option-height="24"
        :show-labels="false"
        @input="filterTable"
      />
    </div>
    <div class="row">
      <div class="column small-12 medium-12 conversation-metric">
        <metric-card
          :live="false"
          :header="$t('CAMPAIGN_REPORTS.METRICS.CAMPAIGNS.TITLE')"
        >
          <div
            v-for="(metric, name, index) in campaignsMetrics"
            :key="index"
            class="metric-content column"
          >
            <h3 class="heading">
              {{ name }}
            </h3>
            <p class="metric">{{ metric }}</p>
          </div>
        </metric-card>
      </div>
    </div>
    <div v-if="tableData.length" class="row">
      <metric-card :header="selectedCampaign.title" :live="false">
        <div
          v-for="(metric, name, index) in selectedCampaignMetrics"
          :key="index"
          class="metric-content column"
        >
          <h3 class="heading">
            {{ name }}
          </h3>
          <p class="metric">{{ metric }}</p>
        </div>
      </metric-card>
      <metric-card
        :live="false"
        :header="$t('CAMPAIGN_REPORTS.TABLE.TITLE')"
        class="pb-10"
      >
        <campaign-table :data="tableData" :columns="tableColumns" />
      </metric-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MetricCard from './components/overview/MetricCard';
import CampaignTable from './components/campaign/CampaignTable';

export default {
  name: 'CampaignReports',
  components: {
    MetricCard,
    CampaignTable,
  },
  data() {
    return {
      selectedCampaign: null,
      tableData: [],
      tableColumns: [],
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      campaignsList: 'campaigns/getAllCampaigns',
    }),
    campaignsMetrics() {
      return {
        [this.$t('CAMPAIGN_REPORTS.METRICS.CAMPAIGNS.CAMPAIGNS_QUANTITY')]:
          this.campaignsList.length,
        [this.$t('CAMPAIGN_REPORTS.METRICS.CAMPAIGNS.COMPLETED')]:
          this.campaignsList.filter(
            campaign => campaign.campaign_status === 'completed'
          ).length,
        [this.$t('CAMPAIGN_REPORTS.METRICS.CAMPAIGNS.PENDING')]:
          this.campaignsList.filter(
            campaign => campaign.campaign_status !== 'completed'
          ).length,
      };
    },
    selectedCampaignMetrics() {
      return {
        [this.$t('CAMPAIGN_REPORTS.METRICS.MESSAGES_SENT.SENT')]:
          this.selectedCampaign.messagesSent.length,
        [this.$t('CAMPAIGN_REPORTS.METRICS.MESSAGES_SENT.DELIVERED')]:
          this.selectedCampaign.messagesSent.filter(
            message =>
              message.status === 'delivered' || message.status === 'read'
          ).length,
        [this.$t('CAMPAIGN_REPORTS.METRICS.MESSAGES_SENT.READED')]:
          this.selectedCampaign.messagesSent.filter(
            message => message.status === 'read'
          ).length,
        [this.$t('CAMPAIGN_REPORTS.METRICS.MESSAGES_SENT.ERROR')]:
          this.selectedCampaign.messagesSent.filter(
            message => message.status === 'error' || message.status === 'failed'
          ).length,
        [this.$t('CAMPAIGN_REPORTS.METRICS.MESSAGES_SENT.DUPLICITY')]:
          this.selectedCampaign.messagesSent.filter(
            message => message.status === 'duplicity'
          ).length,
      };
    },
    filterItemsList() {
      return this.campaignsList.map(campaign => ({
        messagesSent: campaign.messages_sent,
        ...campaign,
      }));
    },
  },
  mounted() {
    this.$store.dispatch('campaigns/get');
  },

  methods: {
    filterTable() {
      if (!this.selectedCampaign) {
        this.tableData = [];
        return;
      }

      if (this.selectedCampaign.campaign_type === 'email') {
        this.tableColumns = ['email', 'status'];
        this.tableData = this.selectedCampaign.messagesSent.map(message => {
          return {
            email: message.to,
            status: this.$t(
              `CAMPAIGN_REPORTS.MESSAGES_STATUS.${message.status?.toUpperCase()}`
            ),
          };
        });

        return;
      }

      this.tableColumns = undefined;
      this.tableData = this.selectedCampaign.messagesSent.map(message => {
        return {
          phoneNumber: message.phone_number,
          status: this.$t(
            `CAMPAIGN_REPORTS.MESSAGES_STATUS.${message.status?.toUpperCase()}`
          ),
          contactId: message.contact_id,
        };
      });
    },
  },
};
</script>

<template>
  <div class="column content-box p-2">
    <div class="row-content">
      <input
        v-model="initialDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de inicio"
      />
      <input
        v-model="endDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de fim"
        style="margin-left: 10px"
      />
    </div>

    <div class="second-div">
      <input
        v-model="searchTextButton"
        type="text"
        placeholder="Digite o texto do botão de interação"
        style="height: 45px"
      />

      <multiselect
        v-model="inboxesIds"
        placeholder="Selecione uma caixa de entrada"
        label="name"
        track-by="id"
        :options="inboxes"
        :show-labels="false"
        :multiple="true"
        style="margin-left: 10px"
      />

      <div class="div-button">
        <woot-button
          :disabled="!disabledButton"
          color-scheme="success"
          style="height: 45px"
          @click="fetchTabulationData"
        >
          Buscar
        </woot-button>
      </div>
    </div>
    <metric-card :live="true" :header="'Dados das Conversas'">
      <div class="interactive--table-container">
        <div v-if="!loading" class="interactive--empty-records">
          <p class="font-medium mb-0 text-slate-800 dark:text-slate-100">
            Foram encontradas
            <strong class="font-bold mb-0 text-slate-800 dark:text-slate-100">{{
              numberOfFounds
            }}</strong>
            correspondências
            <strong
              v-if="reportsData.length"
              class="font-bold mb-0 text-slate-800 dark:text-slate-100"
            >
              para {{ searchTextButton }}
            </strong>
          </p>
        </div>
        <div v-if="loading" class="interactive--empty-records">
          <span>{{ $t('CAMPAIGN_REPORTS.TABLE.LOADING_MESSAGE') }}</span>
        </div>
        <ve-table
          v-if="!loading && reportsData.length"
          :fixed-header="true"
          :columns="columns"
          :table-data="reportsData"
        />
      </div>
    </metric-card>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import reportsTabulation from '../../../../api/getTabulationData';
import MetricCard from './components/overview/MetricCard';

import { mapGetters } from 'vuex';
import { format } from 'date-fns';
export default {
  components: {
    VeTable,
    MetricCard,
  },
  data() {
    return {
      reportsData: [],
      initialDate: format(new Date(), 'dd/MM/yyyy'),
      endDate: format(new Date(), 'dd/MM/yyyy'),
      loading: false,
      searchTextButton: '',
      numberOfFounds: 0,
      inboxesIds: [],
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxesSelect',
    }),
    columns() {
      return [
        {
          field: 'name',
          key: 'name_1',
          title: 'Nome Agente',
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'count',
          key: 'count',
          title: 'Conversas Atribuidas',
          align: 'left',
          width: 10,
        },
      ];
    },
    disabledButton() {
      return Boolean(
        this.initialDate.length === 10 &&
          this.endDate.length === 10 &&
          this.searchTextButton.length &&
          this.inboxesIds.length
      );
    },
  },
  methods: {
    async fetchTabulationData() {
      this.reportsData = [];
      this.numberOfFounds = 0;
      this.loading = true;
      const init = String(this.initialDate).split('/');
      const end = String(this.endDate).split('/');
      const initialDate = `${init[2]}-${init[1]}-${init[0]}`;
      const endDate = `${end[2]}-${end[1]}-${end[0]}`;

      // eslint-disable-next-line vars-on-top
      var inboxesId = `(${this.inboxesIds.map(item => item.id).join(',')})`;

      if (this.inboxesIds.map(item => item.id).includes('all')) {
        inboxesId = `(${this.inboxes
          .filter(item => item.id !== 'all')
          .map(item => item.id)
          .join(',')})`;
      }

      try {
        const { data } = await reportsTabulation.getSearchReports(
          this.accountId,
          initialDate,
          endDate,
          this.searchTextButton,
          inboxesId
        );

        this.numberOfFounds = data.reduce((acc, att) => {
          return acc + att.count;
        }, 0);
        this.reportsData = data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row-content {
  display: flex;
}

.div-button {
  width: 130px;
  margin-left: 10px;
}

.second-div {
  display: flex;
  height: 45px;
}

.interaction--table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    @apply bg-white dark:bg-slate-900;

    &::v-deep {
      .ve-table-container {
        border-radius: var(--border-radius-normal);
      }

      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-normal) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-small) var(--space-normal) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .emoji-response {
    font-size: var(--font-size-large);
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.interaction--empty-records {
  align-items: center;
  border-top: 0;
  display: flex;
  font-size: var(--font-size-small);
  height: 12.5rem;
  justify-content: center;
  margin-top: -1px;
  width: 100%;
  @apply text-slate-600 dark:text-slate-200 bg-white dark:bg-slate-900 border border-t-0 border-solid border-slate-75 dark:border-slate-700;
}
</style>

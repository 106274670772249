<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.TELEPHONY.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.TELEPHONY.DESC')"
    />

    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TELEPHONY.INBOX_NAME.PLACEHOLDER')"
            @blur="$v.inboxName.$touch"
          />
          <span v-if="$v.inboxName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.sip.$error }">
          {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_SIP.LABEL') }}
          <input
            v-model.trim="sip"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TELEPHONY.INBOX_SIP.PLACEHOLDER')"
            @blur="$v.sip.$touch"
          />
          <span v-if="$v.sip.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_SIP.ERROR') }}
          </span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.userName.$error }">
          {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_USERNAME.LABEL') }}
          <input
            v-model.trim="userName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TELEPHONY.INBOX_USERNAME.ERROR')"
            @blur="$v.userName.$touch"
          />
          <span v-if="$v.userName.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_USERNAME.ERROR') }}
          </span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.TELEPHONY.TECH_PREFIX.LABEL') }}
          <input
            v-model.trim="techPrefix"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TELEPHONY.TECH_PREFIX.ERROR')"
          />
          <!-- <span v-if="$v.techPrefix.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TELEPHONY.TECH_PREFIX.ERROR') }}
          </span> -->
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.password.$error }">
          {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_PASSWORD.LABEL') }}
          <input
            v-model.trim="password"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_PASSWORD.PLACEHOLDER')
            "
            @blur="$v.password.$touch"
          />
          <span v-if="$v.password.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_PASSWORD.ERROR') }}
          </span>
        </label>
      </div>

      <div class="medium-8 columns">
        <label :class="{ error: $v.wss.$error }">
          <span>
            {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_WSS.LABEL') }}
          </span>
          <input
            v-model.trim="wss"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.TELEPHONY.INBOX_WSS.PLACEHOLDER')"
            @blur="$v.wss.$touch"
          />
          <span v-if="$v.wss.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.TELEPHONY.INBOX_WSS.ERROR') }}
          </span>
        </label>
      </div>

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.TELEPHONY.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';

export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      provider: 'telephony',
      inboxName: '',
      sip: '',
      userName: '',
      password: '',
      wss: '',
      techPrefix: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
  },
  validations: {
    inboxName: { required },
    sip: { required },
    userName: { required },
    password: { required },
    wss: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'telephony',
              sip: this.sip,
              user_name: this.userName,
              password: this.password,
              wss: this.wss,
              tech_prefix: this.techPrefix,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

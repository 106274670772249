<template>
  <div class="w-full h-full dark:bg-slate-800">
    <iframe src="http://localhost:3001" class="w-full h-full" />
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  computed: {},
};
</script>

<template>
  <div>
    <div v-if="!accessToken" class="medium-4 columns">
      <a href="#" @click="tryFBlogin()">
        <img
          src="~dashboard/assets/images/channels/facebook_login.png"
          alt="Facebook-logo"
        />
      </a>
    </div>
    <div v-if="accessToken" class="medium-8 columns">
      <label>
        Selecione a empresa
        <select v-model="selectEnterprise" @click="handleSelectEnterprise">
          <option
            v-for="enterprise in enterprises"
            :key="enterprise.id"
            :value="enterprise.id"
          >
            {{ enterprise.name }}
          </option>
        </select>
      </label>
    </div>
    <div v-if="selectEnterprise" class="medium-8 columns">
      <label>
        Selecione um aplicativo
        <select v-model="selectApp" @click="handleSelectApp">
          <option v-for="app in apps" :key="app.id" :value="app.id">
            {{ app.name }}
          </option>
        </select>
      </label>
    </div>

    <div v-if="isLoading" class="medium-8 columns">
      <p>Carregando dados...</p>
    </div>

    <div v-if="selectApp && !isLoading" class="medium-8 columns">
      <label>
        Selecione um WhatsApp Business Account
        <select v-model="selectAcc" @click="handleSelectAcc">
          <option
            v-for="waba in wabas"
            :key="waba.wabaId"
            :value="waba.nameInbox"
          >
            {{ waba.nameInbox }}
          </option>
        </select>
      </label>
    </div>
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
            @blur="$v.inboxName.$touch"
          />
        </label>
      </div>

      <div v-if="accessToken" class="medium-8 columns">
        <label>
          Selecione a empresa
          <select v-model="selectEnterprise" @click="handleSelectEnterprise">
            <option
              v-for="enterprise in enterprises"
              :key="enterprise.id"
              :value="enterprise.id"
            >
              {{ enterprise.name }}
            </option>
          </select>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.phoneNumber.$error }">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
          <input
            v-model.trim="phoneNumber"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')
            "
            @blur="$v.phoneNumber.$touch"
          />
          <span v-if="$v.phoneNumber.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.phoneNumberId.$error }">
          <span>
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.LABEL') }}
          </span>
          <input
            v-model.trim="phoneNumberId"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.PLACEHOLDER')
            "
            @blur="$v.phoneNumberId.$touch"
          />
          <span v-if="$v.phoneNumberId.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER_ID.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.businessAccountId.$error }">
          <span>
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.LABEL') }}
          </span>
          <input
            v-model.trim="businessAccountId"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.PLACEHOLDER')
            "
            @blur="$v.businessAccountId.$touch"
          />
          <span v-if="$v.businessAccountId.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.BUSINESS_ACCOUNT_ID.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.apiKey.$error }">
          <span>
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.LABEL') }}
          </span>
          <input
            v-model.trim="apiKey"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.PLACEHOLDER')"
            @blur="$v.apiKey.$touch"
          />
          <span v-if="$v.apiKey.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-full">
        <div class="medium-8 columns">
          <label :class="{ error: $v.appId.$error }">
            <span> App id </span>
            <input
              v-model.trim="appId"
              type="text"
              placeholder="App id"
              @blur="$v.appId.$touch"
            />
            <span v-if="$v.appId.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-8 columns">
          <label :class="{ error: $v.appSecret.$error }">
            <span> App secret </span>
            <input
              v-model.trim="appSecret"
              type="text"
              placeholder="App secret"
              @blur="$v.appSecret.$touch"
            />
            <span v-if="$v.appSecret.$error" class="message">
              {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_KEY.ERROR') }}
            </span>
          </label>
        </div>
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty, isNumber } from 'shared/helpers/Validators';

export default {
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      appId: '',
      appSecret: '',
      apiKey: '',
      phoneNumberId: '',
      businessAccountId: '',
      accessToken: '',
      selectEnterprise: '',
      selectApp: '',
      enterprises: [],
      apps: [],
      wabas: [],
      selectAcc: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags', inboxes: 'inboxes/getInboxes' }),
  },
  created() {

    window.fbAsyncInit = function() {
      FB.init({
        appId      : '1352014745539905',
        cookie     : true,                     // Enable cookies to allow the server to access the session.
        xfbml      : true,                     // Parse social plugins on this webpage.
        version    : 'v16.0'           // Use this Graph API version for this call.
      });
    };

    (function(d, s, id) {
      var js;
      var fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

  },
  mounted() {
    setTimeout(() => this.checkLoginState, 5000);
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
    appId: { required },
    appSecret: { required },
    apiKey: { required },
    phoneNumberId: { required, isNumber },
    businessAccountId: { required, isNumber },
  },
  methods: {

    loadingEnterprises() {
      fetch('https://graph.facebook.com/v16.0/me/businesses', {
        headers: {
          authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then(res => res.json())
        .then(res => {
          this.enterprises = res.data;
        });
    },

    tryFBlogin() {
      FB.login((response) => {
        if (response.authResponse) {
          this.accessToken = response.authResponse.accessToken;
          fetch('https://graph.facebook.com/v16.0/me/businesses', {
            headers: {
              authorization: `Bearer ${this.accessToken}`,
            },
          })
            .then(res => res.json())
            .then(res => {
              this.enterprises = res.data;
          });

          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      }, {
          config_id: '361170603127854',
          response_type: 'code',
          override_default_response_type: true // configuration ID goes here
      });
    },

    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'whatsapp',
              phone_number: this.phoneNumber,
              provider: 'whatsapp_cloud',
              provider_config: {
                app_id: this.appId,
                app_secret: this.appSecret,
                api_key: this.apiKey,
                phone_number_id: this.phoneNumberId,
                business_account_id: this.businessAccountId,
              },
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        );
      }
    },

    checkLoginState() {
      FB.getLoginStatus((response) => {
        this.accessToken = response.authResponse.accessToken;
        this.loadingEnterprises();
      });
    },

    handleSelectEnterprise() {
      fetch(
        `https://graph.facebook.com/v16.0/${this.selectEnterprise}/owned_apps`,
        {
          headers: {
            authorization: `Bearer ${this.accessToken}`,
          },
        }
      )
      .then(res => res.json())
      .then(res => {
          this.apps = res.data;
      });
    },

    async handleSelectApp() {
      this.isLoading = true;
      const arr = [];
      const getWabas = await fetch(
        `https://graph.facebook.com/v16.0/${this.selectEnterprise}/owned_whatsapp_business_accounts`,
        {
          headers: {
            authorization: `Bearer ${this.accessToken}`,
          },
        }
      )
      .then(res => res.json())
      .then(res => res.data);

      for (let i = 0; i < getWabas.length; i++) {
        const numberAcc = await fetch(
          `https://graph.facebook.com/v16.0/${getWabas[i].id}/phone_numbers`,
          {
            headers: {
              authorization: `Bearer ${this.accessToken}`,
            },
          }
        )
          .then(res => res.json())
          .then(res => res.data[0]);

        if (!numberAcc) {
          continue;
        }

        arr.push({
          wabaId: getWabas[i].id,
          phoneNumber: numberAcc.display_phone_number,
          phoneId: numberAcc.id,
          appId: this.selectApp,
          nameInbox: `${getWabas[i].name} - ${numberAcc.display_phone_number}`,
        });
      }

      this.wabas = arr;

      this.isLoading = false;
    },
    handleSelectAcc() {
      const accFind = this.wabas.find(
        item => item.nameInbox === this.selectAcc
      );

      const existsAppId = this.inboxes.find(item => item.appId === accFind.appId);
      this.appId = accFind.appId;
      this.phoneNumber = accFind.phoneNumber;
      this.phoneNumberId = accFind.phoneId;
      this.businessAccountId = accFind.wabaId;
      this.inboxName = accFind.nameInbox;
      this.apiKey = this.accessToken;
    },
  },
};
</script>

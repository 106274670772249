import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import MongoEmailTemplatesAPI from '../../api/mongoEmailTemplates';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getTemplates: $state => {
    return $state.records;
  },
  getTemplate: $state => id => {
    return $state.records.find(t => t.id === id);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_MONGO_EMAIL_TEMPLATES_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await MongoEmailTemplatesAPI.get();
      commit(types.SET_MONGO_EMAIL_TEMPLATES, response.data.payload);
      commit(types.SET_MONGO_EMAIL_TEMPLATES_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.SET_MONGO_EMAIL_TEMPLATES_UI_FLAG, {
        isFetching: false,
      });
    }
  },
};

export const mutations = {
  [types.SET_MONGO_EMAIL_TEMPLATES_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_MONGO_EMAIL_TEMPLATES]: MutationHelpers.set,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <iframe
    class="column"
    src="https://templatesmail.digitalchat.com.br"
    frameborder="0"
  />
</template>

<script>
export default {};
</script>

import { frontendURL } from '../../../../helper/URLHelper';

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: [
    'settings_account_campaigns',
    'one_off',
    'telephony_campaigns',
    'email_campaigns',
    'ongoing_campaigns',
  ],
  menuItems: [
    {
      icon: 'arrow-swap',
      label: 'ONGOING',
      key: 'ongoingCampaigns',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/ongoing`),
      toStateName: 'ongoing_campaigns',
    },
    {
      key: 'oneOffCampaigns',
      icon: 'sound-source',
      label: 'ONE_OFF',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
      toStateName: 'one_off',
    },
    {
      key: 'telephonyCampaigns',
      icon: 'sound-source',
      label: 'TELEPHONY',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/telephony`),
      toStateName: 'telephony_campaigns',
    },
    // {
    //   key: 'emailCampaigns',
    //   icon: 'mail-inbox-all',
    //   label: 'EMAIL_CAMPAIGNS',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/campaigns/email`),
    //   toStateName: 'email_campaigns',
    // },
  ],
});

export default campaigns;

import Wrapper from '../Wrapper';
import Index from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/email-templates'),
      component: Wrapper,
      roles: ['administrator'],
      props: {
        headerTitle: 'SIDEBAR.EMAIL_TEMPLATES',
        icon: 'mail',
      },
      children: [
        {
          path: '',
          name: 'email_templates',
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};

import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import FunnelsAPI from '../../api/funnel';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getFunnels: $state => {
    return $state.records;
  },
  getStageDeals: $state => {
    return $state;
  },
  getFunnel: $state => id => {
    return $state.records.find(t => t.id === id);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_FUNNELS_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await FunnelsAPI.get();
      commit(types.SET_FUNNELS, response.data.payload);
      commit(types.SET_FUNNELS_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.SET_FUNNELS_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  create: async ({ commit }, obj) => {
    commit(types.SET_FUNNELS_UI_FLAG, { isCreating: true });
    try {
      const { data } = await FunnelsAPI.create(obj);
      commit(types.CREATED_FUNNEL, data.funnel);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_FUNNELS_UI_FLAG, { isCreating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_FUNNELS_UI_FLAG, { isDeleting: true });
    try {
      await FunnelsAPI.delete(id);
      commit(types.DELETE_FUNNEL, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_FUNNELS_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_FUNNELS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_FUNNELS]: MutationHelpers.set,
  [types.DELETE_FUNNEL]: MutationHelpers.destroy,
  [types.CREATED_FUNNEL]: MutationHelpers.create,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="column content-box p-2 overflow-auto">
    <div class="row-content">
      <input
        v-model="initialDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de inicio"
      />
      <input
        v-model="endDate"
        v-mask="'##/##/####'"
        type="text"
        placeholder="Digite uma data de fim"
        style="margin-left: 10px"
      />
      <div class="div-button">
        <woot-button
          :disabled="!disabledButton"
          color-scheme="success"
          @click="fetchExtractData"
        >
          Buscar
        </woot-button>
      </div>
    </div>
    <div v-if="loading" class="small-12 medium-7 conversation-metric">
      <span>{{ $t('CAMPAIGN_REPORTS.TABLE.LOADING_MESSAGE') }}</span>
    </div>
    <div v-if="Object.keys(valuesData).length" class="row pb-2 justify-between">
      <div class="column small-12 medium-6">
        <div class="card dark:bg-slate-800">
          <div class="card-header">
            <h5 class="dark:text-woot-50">Dados Gerais</h5>
          </div>
          <div class="card-body row">
            <div class="metric-content column">
              <h3 class="heading dark:text-woot-50">Enviadas</h3>
              <p class="metric dark:text-woot-50">{{ valuesData.enviadas }}</p>
            </div>
            <div class="metric-content column">
              <h3 class="heading dark:text-woot-50">Erros</h3>
              <p class="metric dark:text-woot-50">{{ valuesData.erros }}</p>
            </div>
            <div class="metric-content column">
              <h3 class="heading dark:text-woot-50">Efetivas</h3>
              <p class="metric dark:text-woot-50">{{ valuesData.efetivas }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="column small-12 medium-6">
        <div class="card dark:bg-slate-800">
          <div class="card-header">
            <h5 class="dark:text-woot-50">Financeiro</h5>
          </div>
          <div class="card-body row">
            <div class="metric-content column">
              <h3 class="heading dark:text-woot-50">Entradas</h3>
              <p class="metric dark:text-woot-50">
                {{ valuesData.valorentradas }}
              </p>
            </div>
            <div class="metric-content column">
              <h3 class="heading dark:text-woot-50">Saídas</h3>
              <p class="metric dark:text-woot-50">
                {{ valuesData.valortotal }}
              </p>
            </div>
            <div class="metric-content column">
              <h3 class="heading dark:text-woot-50">Saldo</h3>
              <p class="metric dark:text-woot-50">
                {{ valuesData.saldo }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="extractData.length" class="card dark:bg-slate-800">
      <div class="card-header">
        <h5 class="dark:text-woot-50">Histórico de Transações</h5>
      </div>
      <div class="card-body">
        <ve-table
          :fixed-header="true"
          :columns="columns"
          :table-data="extractData"
          :max-height="650"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import reportsExtract from '../../../../api/reports';
import { mapGetters } from 'vuex';
import { format, lastDayOfMonth, startOfMonth } from 'date-fns';
export default {
  components: {
    VeTable,
  },
  data() {
    return {
      extractData: [],
      tabulationData: [],
      initialDate: format(startOfMonth(new Date()), 'dd/MM/yyyy'),
      endDate: format(lastDayOfMonth(new Date()), 'dd/MM/yyyy'),
      valuesData: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    columns() {
      return [
        {
          field: 'created_at',
          key: 'created_at',
          title: 'Data',
          fixed: 'left',
          align: 'left',
          width: 10,
        },
        {
          field: 'descricao',
          key: 'descricao',
          title: 'Campanhas',
          align: 'left',
          width: 10,
        },
        {
          field: 'enviadas',
          key: 'enviadas',
          title: 'Enviadas',
          align: 'left',
          width: 10,
        },
        {
          field: 'duplicidades',
          key: 'duplicidades',
          title: 'Duplicadas',
          align: 'left',
          width: 10,
        },
        {
          field: 'erros',
          key: 'erros',
          title: 'Erros',
          align: 'left',
          width: 10,
        },
        {
          field: 'efetivas',
          key: 'efetivas',
          title: 'Efetivas',
          align: 'left',
          width: 10,
        },
        {
          field: 'nti',
          key: 'nti',
          title: 'NTI',
          align: 'left',
          width: 10,
        },
        {
          field: 'ti',
          key: 'ti',
          title: 'TI',
          align: 'left',
          width: 10,
        },
        {
          field: 'valor',
          key: 'valor_1',
          title: 'Valor',
          align: 'left',
          width: 10,
        },
      ];
    },
    disabledButton() {
      return Boolean(
        this.initialDate.length === 10 && this.endDate.length === 10
      );
    },
  },
  mounted() {
    this.fetchExtractData();
  },
  methods: {
    async fetchExtractData() {
      const { data } = await reportsExtract.getExtractReports({
        from: this.initialDate,
        to: this.endDate,
      });

      if (data.extract.length) {
        this.extractData = data.extract.map(item => {
          const dateFormat = item.created_at.split('-');
          return {
            ...item,
            descricao: item.descricao.replace('Campanhas', ''),
            created_at: `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`,
          };
        });
      }

      this.valuesData = {
        valortotal: this.formatToReal(data.values.valortotal),
        valorentradas: this.formatToReal(data.values.valorentradas),
        campanhas: data.values.campanhas,
        enviadas: data.values.enviadas,
        erros: data.values.erros,
        efetivas: data.values.efetivas,
        saldo: this.formatToReal(
          data.values.valorentradas - data.values.valortotal
        ),
      };
      this.loading = false;
    },
    formatToReal(value) {
      return Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    },
  },
};
</script>

<style>
.row-content {
  display: flex;
}

.div-button {
  width: 130px;
  margin-left: 10px;
}
</style>

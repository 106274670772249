<template>
  <div
    v-show="Object.keys(inbox).length || uiFlags.isCalling"
    class="relative bottom-20 left-20 z-50 w-2/5"
  >
    <div
      class="flex items-center justify-between py-1 px-2 w-full rounded-lg shadow-lg bg-yellow-200 dark:bg-yellow-700 relative"
    >
      <fluent-icon
        emoji="📞"
        icon="call"
        class="text-yellow-700/50 dark:text-yellow-50"
        size="18"
      />
      <span
        class="text-xs tracking-wide font-medium px-2 text-yellow-700/70 dark:text-yellow-50"
      >
        {{ message }} <br />
        {{ timeFormated }}
      </span>
      <div>
        <woot-button
          color-scheme="success"
          :is-disabled="uiFlags.isCalling"
          emoji="📞"
          icon="call"
          @click="makeCall"
        >
          Iniciar ligação
        </woot-button>
        <woot-button
          :is-disabled="!uiFlags.isCalling"
          class="alert"
          emoji="X"
          icon="dismiss-circle"
          @click="hangUp"
        >
          Desligar
        </woot-button>
      </div>
    </div>
  </div>
</template>

<script>
import JsSIP from 'jssip';
import { mapGetters } from 'vuex';
import { intervalToDuration } from 'date-fns';
import alertMixin from 'shared/mixins/alertMixin';
import mitt from 'mitt';

const emitter = mitt();

const MESSAGE = {
  REJECTED: 'Ligação foi rejeitada',
  BUSY: 'O numero se encontra em outra chamada',
  UNAVAILABLE: 'Ligação foi rejeitada',
  CANCELED: 'Ligação foi cancelada',
};

export default {
  mixins: [alertMixin],
  data() {
    return {
      time: 0,
      timeFormated: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'call/getUIFlags',
      message: 'call/getMessage',
      currentUser: 'getCurrentUser',
      sessionState: 'call/getSession',
      dataCreated: 'call/getData',
      inbox: 'call/getInboxSelected',
    }),
  },
  mounted() {
    // emitter.on('newToastMessage', this.onNewToastMessage);
    emitter.on('endCallClient', this.hangUp);
    emitter.on('initCallToClient', this.makeCall);
  },
  beforeDestroy() {
    // emitter.off('newToastMessage');
    emitter.off('endCallClient');
    emitter.off('initCallToClient');
  },
  methods: {
    endCall(status) {
      this.$store.dispatch('call/create', {
        inbox_id: this.inbox.id,
        call_duration: this.time,
        contact_id: this.inbox.contact.id,
        agent_id: this.currentUser.id,
        status_call: status,
      });
      this.setTimesInitialize();
      emitter.off('endCallClient');
      emitter.off('initCallToClient');
      this.$store.dispatch('call/endCall');
    },
    async createMessage() {
      const messagePayload = {
        inbox_id: this.inbox.id,
        call_duration: this.time,
        contact_id: this.inbox.contact.id,
        agent_id: this.currentUser.id,
      };

      this.setTimesInitialize();

      await this.$store.dispatch('call/create', messagePayload);
    },
    timer() {
      // eslint-disable-next-line no-plusplus
      this.time++;
      const messageData = intervalToDuration({
        start: 0,
        end: Number(this.time) * 1000,
      });

      const formatedHours = `${this.toStringParse(
        messageData.hours
      )}:${this.toStringParse(messageData.minutes)}:${this.toStringParse(
        messageData.seconds
      )}`;

      this.$store.dispatch('call/updateTime', {
        time: this.time,
        timeFormat: formatedHours,
      });

      this.timeFormated = formatedHours;
    },
    toStringParse(element) {
      return String(element).padStart(2, '0');
    },
    setTimesInitialize() {
      this.time = 0;
      this.timeFormated = '';
    },
    sleep(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    },
    async initializePhone() {
      const configuration = {
        uri: this.inbox.sip,
        password: this.inbox.password,
        username: this.inbox.user_name,
        sockets: [new JsSIP.WebSocketInterface(this.inbox.wss)],
        register: true,
      };

      this.phone = new JsSIP.UA(configuration);
      JsSIP.debug.disable('JsSIP:*');
      this.phone.start();

      this.phone.on('newRTCSession', data => {
        this.$store.dispatch('call/setSession', data.session);
        this.session = data.session;

        const remoteAudio = new Audio();
        remoteAudio.autoplay = true;
        remoteAudio.crossOrigin = 'anonymous';

        // Supondo que `session` seja uma propriedade reativa do Vue, você precisará garantir que ela esteja definida corretamente
        this.session.connection.addEventListener('addstream', e => {
          remoteAudio.srcObject = e.stream;
        });

        this.session.connection.addEventListener(
          'connectionstatechange',
          () => {
            if (
              this.session.connection.connectionState === 'failed' ||
              this.session.connection.connectionState === 'closed'
            ) {
              this.onCallEnded();
            }
          }
        );

        if (data.originator === 'remote') {
          this.incomingCallVisible = true;
          this.callControlVisible = false;
          this.incomingCallNumber = this.session.remote_identity.uri.toString();
        }

        this.session.on('ended', this.onCallEnded);
        this.session.on('failed', this.onCallFailed);
        this.session.on('confirmed', this.onCallConfirmed);
        this.session.on('progress', this.onProgress);
      });
    },
    formatPhone(phone) {
      var phone_number = phone.replace('+', '');

      const justNumber = phone_number.slice(4, phone_number.length);

      if (
        phone_number.length <= 12 &&
        !['2', '3', '4', '5'].includes(justNumber.slice(0, 1))
      ) {
        phone_number = `${phone_number.slice(0, 4)}9${phone_number.slice(
          4,
          phone_number.length
        )}`;
      }

      if (this.inbox.tech_prefix) {
        phone_number = phone_number.includes('5511')
          ? phone_number.replace('5511', this.inbox.tech_prefix)
          : this.inbox.tech_prefix + '0' + phone_number.replace('55', '');
      }

      return phone_number;
    },
    async makeCall() {
      await this.initializePhone();
      this.$store.dispatch('call/updateText', {
        message: 'Chamando...',
      });

      this.$store.dispatch('call/initCall', {
        inbox_id: this.inbox.id,
        contact_id: this.inbox.contact.id,
        agent_id: this.currentUser.id,
      });
      if (this.inbox.contact.phone_number) {
        const phone_number = this.formatPhone(this.inbox.contact.phone_number);
        this.phone.call(phone_number, {
          eventHandlers: {
            progress: () => {
              this.$store.dispatch('call/updateText', {
                message: 'Chamando...',
              });
            },
            confirmed: () => {
              this.$store.dispatch('call/updateText', {
                message: 'Chamada em andamento:',
              });
            },
            ended: () => {
              this.$store.dispatch('call/updateText', {
                message: 'Chamada finalizada:',
              });
            },
            failed: () => {
              this.$store.dispatch('call/updateText', {
                message: 'Chamada falhou:',
              });
            },
          },
          mediaConstraints: { audio: true, video: false },
        });
        this.callControlVisible = false;
      }
    },
    answerCall() {
      this.session.answer({ mediaConstraints: { audio: true, video: false } });
      this.incomingCallVisible = false;
    },
    rejectCall() {
      this.session.terminate();
      this.endCall('reject');
    },
    hangUp() {
      if (this.time > 0) {
        this.endCall('success');
      } else {
        this.endCall('canceled');
      }
      // eslint-disable-next-line no-underscore-dangle
      if (Object.keys(this.session).length > 0 || this.sessionState?._id) {
        if (Object.keys(this.session).length) {
          this.session?.terminate();
        }

        // eslint-disable-next-line no-underscore-dangle
        if (this.sessionState?._id) {
          this.sessionState?.terminate();
        }
      }

      this.session = null;
      this.setTimesInitialize();
      clearInterval(this.interval);
    },
    onProgress() {},
    sendDTMF(tone) {
      if (this.session) {
        this.session.sendDTMF(tone);
      }
    },
    toggleMute() {
      if (this.session) {
        if (this.mute) {
          this.session.unmute();
        } else {
          this.session.mute();
        }
        this.mute = !this.mute;
      }
    },
    onCallEnded(e) {
      this.callControlVisible = true;
      // eslint-disable-next-line no-underscore-dangle
      if (this.sessionState._id && e.originator === 'remote') {
        this.endCall('success');
      }

      if (this.time > 0 && e.originator === 'local') {
        this.endCall('success');
      }

      clearInterval(this.interval);
      this.$store.dispatch('call/endCall');
    },
    onCallFailed(e) {
      if (e.cause) {
        const message = MESSAGE[e.cause.toUpperCase()] || 'Chamada Falhou...';
        this.$store.dispatch('call/updateText', {
          message: message,
        });
        this.showAlert(message);
      }
      this.$store.dispatch('call/endCall');

      this.hangUp();

      setInterval(() => {
        this.$store.dispatch('call/updateText', {
          message: '',
        });
      }, 2500);

      if (e.originator === 'remote') {
        this.endCall('reject');
      }

      if (e.originator === 'local') {
        this.endCall('failed');
      }
    },
    onCallConfirmed() {
      this.$store.dispatch('call/updateText', {
        message: 'Chamada em andamento...',
      });
      this.dialPadVisible = true;
      this.interval = setInterval(() => this.timer(), 1000);
    },
  },
};
</script>

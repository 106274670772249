/* global axios */
import ApiClient from './ApiClient';

class ReportsTabulation extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  getReportsTabulation(accountId, dateInitial, dateEnd, inbox) {
    return axios.get(
      `${this.apiVersion}/accounts/${accountId}/reports_tabulation?initial_date=${dateInitial}&end_date=${dateEnd}&inbox=${inbox}`
    );
  }

  getSearchReports(accountId, dateInitial, dateEnd, content, inboxesId) {
    return axios.get(
      `${this.apiVersion}/accounts/${accountId}/reports_tabulation/search_interaction?initial_date=${dateInitial}&end_date=${dateEnd}&content=${content}&inboxesId=${inboxesId}`
    );
  }
}

export default new ReportsTabulation();
